import { Injectable } from '@angular/core';
import { LocalStorageKey } from './local-storage.constants';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  get(key: LocalStorageKey): string {
    return localStorage.getItem(key) || '';
  }

  set(key: LocalStorageKey, value: string): void {
    localStorage.setItem(key, value);
  }

  remove(key: LocalStorageKey): void {
    localStorage.removeItem(key);
  }
}
