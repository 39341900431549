import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, BehaviorSubject, ReplaySubject, Subject, combineLatest } from 'rxjs';
import { map, shareReplay, take, takeUntil } from 'rxjs/operators';
import { GameVersion } from './version.interface';
import { HttpService } from '../http/http.service';
import { LocalStorageService } from '../local-storage/local-storage.service';
import { LocalStorageKey } from '../local-storage/local-storage.constants';

@Injectable({
  providedIn: 'root'
})
export class VersionService implements OnDestroy {
  private destroy$ = new Subject<void>(); 
  private versions: GameVersion[] = [];
  currentVersion$ = new ReplaySubject<string>(1);
  versionUpdated$ = new BehaviorSubject<boolean>(false);
  private versions$!: Observable<GameVersion[]>;

  constructor(
    private router: Router, 
    private http: HttpService, 
    private localStorage: LocalStorageService, 
    private route: ActivatedRoute
  ) { }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  init(): void {
    combineLatest([
      this.route.paramMap.pipe(take(1)),
      this.getVersions().pipe(takeUntil(this.destroy$))
    ]).subscribe(([params, versions]) => {
      this.versions = versions;
      let paramVersion = params.get('versionNumber');

      paramVersion = paramVersion === 'latest' ? this.versions[0].versionNumber : paramVersion;
      const currentVersion = paramVersion || this.getSavedVersion() || this.versions[0].versionNumber 
      this.setVersion(currentVersion, false);
    });
  }

  getVersions(): Observable<GameVersion[]> {
    if (!this.versions$) {
      this.versions$ = this.http.get<GameVersion[]>(`ttrpg/version/list`)
        .pipe(
          shareReplay(1)
        );
    }
    return this.versions$;
  }

  getLatestVersion(): Observable<string> {
    return this.getVersions().pipe(
      map(versions => versions[0].versionNumber)
    );
  }

  setVersion(versionNumber: string, saveToStorage = true): void {
    if (versionNumber) {
      if (saveToStorage && versionNumber === this.versions[0].versionNumber) {
        this.localStorage.remove(LocalStorageKey.VersionNumber);
      } else if (saveToStorage) {
        this.localStorage.set(LocalStorageKey.VersionNumber, versionNumber);
      }

      this.currentVersion$.next(versionNumber);
      const currentRoute = window.location.pathname;
      const regex = /(\/\d\.\d\.\d\/)|(\/latest\/)/i;
      this.router.navigateByUrl(currentRoute.replace(regex, `/${ versionNumber }/`));
    }
  }

  getUrlVersion():  Observable<string | undefined> {
    return this.currentVersion$.pipe(
      map(version => version === this.versions[0].versionNumber ? 'latest' : version)
    );
  }

  getVersion(): Observable<string | undefined> {
    return this.currentVersion$;
  }

  private getSavedVersion(): string {
    return this.localStorage.get(LocalStorageKey.VersionNumber);
  }
}
