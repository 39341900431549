import { Injectable } from '@angular/core';
import { SessionStorageKey } from './session-storage.constants';

@Injectable({
  providedIn: 'root'
})
export class SessionStorageService {
  get(key: SessionStorageKey): string {
    return sessionStorage.getItem(key) || '';
  }

  set(key: SessionStorageKey, value: string): void {
    sessionStorage.setItem(key, value);
  }

  remove(key: SessionStorageKey): void {
    sessionStorage.removeItem(key);
  }
}
