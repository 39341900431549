import { Injectable } from '@angular/core';
import { from, map, Observable, of, switchMap, take, tap } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EncryptService {
  private key!: CryptoKey;

  constructor() { 
    this.importKey().pipe(
      take(1)
    ).subscribe(key => {
      this.key = key;
    });
  }
 
  importKey(): Observable<CryptoKey> {
    return this.key ? of(this.key) : from(window.crypto.subtle.importKey(
      'jwk',
      {
        kty: 'oct',
        k: environment.aesKey,
        alg: 'A128CBC',
        ext: true
      },
      {
        name: 'AES-CBC'
      },
      false,
      ['encrypt', 'decrypt']
    ));
  }

  encrypt(data: string, iv: string): Observable<string> {
    const encoder = new TextEncoder();
    const decoder = new TextDecoder();

    return this.importKey().pipe(
      switchMap(key => {
        return from(window.crypto.subtle.encrypt({
              name: 'AES-CBC',
              iv: encoder.encode(iv)
            },
            key,
            encoder.encode(data)
          ))
        }
      ),
      map(encrypted => btoa(String.fromCharCode(...new Uint8Array(encrypted)))),
    );
  }

  baseEncode(value: string): string {
    return btoa(value);
  }

  baseDecode(value: string): string {
    try {
      return decodeURIComponent(atob(value).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));
    } catch {
      return '';
    }
  }
}
